// import VuexPersistence from 'vuex-persist';
// import Cookies from 'js-cookie';
import moment from 'moment';

const newTripInfo = () => {
  return {
    changeReason: '',
    travelPurpose: '',
    hostOrganization: '',
    baggageOptionId: '',
    costsCenter: '',
    isAlternateNegotiator: false,
    alternateNegotiator: '',
    negotiator: '',
    isSubordinateRequest: false,
    subordinate: '',
    subordinateGuid: '',
    subordinateEmail: '',
    subordinatePosition: '',
    employeeFullname: localStorage.fullname,
    employeePosition: localStorage.position,
    employeeAddress: localStorage.address,
    employeeEmail: localStorage.login,
    duration: 0,
    trips: [],
    transfers: [],
    hotels: []
  };
};

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   reducer: (state) => ({data: state.tripInfo}),
// })

export default {
  namespaced: true,
  state() {
    const tripInfo = newTripInfo()
    return {
      tripInfo
    }
  },
  getters: {},
  mutations: {
    resetTripInfo(state) {
      state.tripInfo = newTripInfo()
    },
    addTrip(state) {
      const departurePlace = state.tripInfo.trips.length > 0
        ? state.tripInfo.trips[state.tripInfo.trips.length - 1].arrivalPlace
        : ''
      const departureCityCode = state.tripInfo.trips.length > 0
        ? state.tripInfo.trips[state.tripInfo.trips.length - 1].arrivalCityCode
        : ''
      state.tripInfo.trips.push({
        id: state.tripInfo.trips.length + 1,
        tripNumber: state.tripInfo.trips.length + 1,
        transportType: 'plane',
        alternateTransportTypeId: 1,
        alternateTransportTypeComment: '',
        departurePlace: departurePlace,
        departureCityCode: departureCityCode,
        startDate: '',
        endDate: '',
        departure: '',
        arrival: '',
        departureTime: '',
        arrivalTime: ''
      })
    },
    updateTrip(state, data) {
      console.log(data)
      if (data != '' && data != null && data != undefined) {
        state.tripInfo.trips[0].departurePlace = data.departurePlace
        state.tripInfo.trips[0].departureCityCode = data.departureCityCode
        state.tripInfo.trips[0].arrivalPlace = data.arrivalPlace
        state.tripInfo.trips[0].arrivalCityCode = data.arrivalCityCode
        // state.tripInfo.trips[0].transportType = data.transportType == 2 ? 'train' : 'plane'
      }
    },
    addHotelBlock(state) {
      state.tripInfo.hotels.push({
        id: state.tripInfo.hotels.length + 1,
        place: '',
        hotel: '',
        startDate: '',
        endDate: ''
      })
    },
    removeHotelInfoBlock(state, hotelId) {
      state.tripInfo.hotels = state.tripInfo.hotels.filter(el => el.id != hotelId)
    },
    setHotelPlace(state, { id, place }) {
      state.tripInfo.hotels.filter(el => el.id === id)[0].place = place
    },
    setHotel(state, { id, hotel }) {
      state.tripInfo.hotels.filter(el => el.id === id)[0].hotel = hotel
    },
    addTransferBlock(state) {
      state.tripInfo.transfers.push({
        id: state.tripInfo.transfers.length + 1,
        transferTariffTypeId: '',
        transferStartAddress: '',
        transferEndAddress: '',
        transferDate: '',
        transferTime: ''
      })
    },
    removeTransferBlock(state, transferId) {
      state.tripInfo.transfers = state.tripInfo.transfers.filter(el => el.id != transferId)
    },
    addBackTrip(state) {
      const startTrip = state.tripInfo.trips[0]
      const endTrip = state.tripInfo.trips[state.tripInfo.trips.length - 1]
      
      const departure = endTrip === undefined ? startTrip.startDate : (endTrip.departure == '' ? '' : endTrip.departure)
      const arrival = endTrip === undefined ? '' : (endTrip.arrival == '' ? '' : endTrip.arrival)
      const departureTime = endTrip === undefined ? '' : (endTrip.departureTime == '' ? '' : endTrip.departureTime)
      const arrivalTime = endTrip === undefined ? '' : (endTrip.arrivalTime == '' ? '' : endTrip.arrivalTime)
      const alternateTransportTypeId = endTrip === undefined ? 1 : endTrip.alternateTransportTypeId

      const backTrip = {
        id: 2,
        tripNumber: 2,
        transportType: startTrip.transportType,
        alternateTransportTypeId: alternateTransportTypeId,
        alternateTransportTypeComment: '',
        departurePlace: startTrip.arrivalPlace,
        departureCityCode: startTrip.arrivalCityCode,
        arrivalPlace: startTrip.departurePlace,
        arrivalCityCode: startTrip.departureCityCode,
        startDate: startTrip.endDate,
        endDate: startTrip.endDate,
        departure: departure,
        arrival: arrival,
        departureTime: departureTime,
        arrivalTime: arrivalTime
      }

      console.log(backTrip)


      var backTripThread = undefined
      // var isTicketSelected = false
      if (state.tripInfo.trips.length > 1) {
        backTripThread = state.tripInfo.trips[state.tripInfo.trips.length - 1].thread
        // isTicketSelected = state.tripInfo.trips[state.tripInfo.trips.length - 1].isTicketSelected
        state.tripInfo.trips.pop()
      }
      // Положить thread в новый backTrip и затем сохранить backTrip
      state.tripInfo.trips.push(backTrip)
      state.tripInfo.trips[state.tripInfo.trips.length - 1].thread = backTripThread
      // state.tripInfo.trips[state.tripInfo.trips.length - 1].isTicketSelected = isTicketSelected
      console.log(state.tripInfo)
    },
    removeBackTrip(state) {
      if (state.tripInfo.trips.length > 1) {
        state.tripInfo.trips.pop()
        state.tripInfo.trips[0].endDate = ''
      }
      console.log(state.tripInfo.trips[0].endDate)
    },
    removeTrip(state) {
      state.tripInfo.trips.pop()
    },
    updateDepartureCityCode(state, { id, departureCityCode }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].departureCityCode = departureCityCode
    },
    updateArrivalCityCode(state, { id, arrivalCityCode }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalCityCode = arrivalCityCode
    },
    setAdditionalTripData(state, { id, additionalData }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].departure = additionalData.departure
      state.tripInfo.trips.filter(el => el.id === id)[0].arrival = additionalData.arrival
      state.tripInfo.trips.filter(el => el.id === id)[0].departureTime = additionalData.departureTime
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalTime = additionalData.arrivalTime
      state.tripInfo.trips.filter(el => el.id === id)[0].departureCity = additionalData.departureCity
      state.tripInfo.trips.filter(el => el.id === id)[0].departureCountry = additionalData.departureCountry
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalCity = additionalData.arrivalCity
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalCountry = additionalData.arrivalCountry
      state.tripInfo.trips.filter(el => el.id === id)[0].employeeId = additionalData.employeeId
      state.tripInfo.trips.filter(el => el.id === id)[0].companyId = additionalData.companyId
      // state.tripInfo.trips.filter(el => el.id === id)[0].endDate = additionalData.endDate
      // state.tripInfo.trips.filter(el => el.id === id)[0].isTicketSelected = additionalData.isTicketSelected
    },
    setDriverData(state, { id, isDriver }) {
      console.log(isDriver)
      state.tripInfo.trips.filter(el => el.id === id)[0].transportType = 'car'
      if (isDriver) {
        state.tripInfo.trips.filter(el => el.id === id)[0].alternateTransportTypeId = 3
      } else {
        state.tripInfo.trips.filter(el => el.id === id)[0].alternateTransportTypeId = 1
      }
    },
    // setTicketSelected(state, { id }) {
      // state.tripInfo.trips.filter(el => el.id === id)[0].isTicketSelected = true
    // },
    addThread(state, { id , thread }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].thread = thread
    },
    addCalculations(state, { tripDuration, routeType, isBusinessTrip, isDriver, originalRequestId }) {
      state.tripInfo.isComplexRouteType = routeType
      state.tripInfo.isBusinessTrip = isBusinessTrip
      state.tripInfo.isDriver = isDriver
      state.tripInfo.employeeId = localStorage.getItem("employeeId")
      state.tripInfo.companyId = localStorage.getItem("companyId")
      state.tripInfo.duration = tripDuration
      if (originalRequestId != 0) {
        state.tripInfo.originalRequestId = originalRequestId
      }

      state.tripInfo.trips.forEach(el => {
        if (el.id === state.tripInfo.trips.length) {
          el.endDate = el.startDate
        } else {
          el.endDate = state.tripInfo.trips[el.id].startDate
        }

        if (el.thread != undefined) {
          el.departure = el.thread.fromLocalDateTime
          el.arrival = el.thread.toLocalDateTime
        } else {
          if (el.departure == null || el.departure == '' || el.departure == undefined) {
            el.departure = el.startDate
          } else {
            el.departure = moment(el.departure).format("YYYY-MM-DDT") + el.departureTime + ':00'
          }
          if (el.arrival == null || el.arrival == '' || el.arrival == undefined) {
            el.arrival = el.startDate
          } else {
            el.arrival = moment(el.arrival).format("YYYY-MM-DDT") + el.arrivalTime + ':00'
          }
        }
      })
    },
    addHotelDuration(state, { id, duration }) {
      state.tripInfo.hotels.filter(el => el.id === id)[0].duration = duration
    },
    setHotelDates(state) {
      state.tripInfo.hotels.forEach((el, index) => {
        if (state.tripInfo.trips[index] != undefined) { 
          el.startDate = state.tripInfo.trips[index].startDate
          el.endDate = state.tripInfo.trips[index].endDate
          el.place = state.tripInfo.trips[index].arrivalCity
        }
      });
    },
    removeThread(state, id) {
      state.tripInfo.trips.filter(el => el.id === id)[0].thread = undefined
      // state.tripInfo.trips.filter(el => Number(el.id) === id)[0].isTicketSelected = false
    },
    resetAlternateTransportType(state, id) {
      state.tripInfo.trips.filter(el => el.id === id)[0].alternateTransportTypeId = 0
    },
    changeTripTransportType(state, id) {
      if (state.tripInfo.trips.filter(el => el.id === id)[0].transportType == 'plane') {
        state.tripInfo.trips.filter(el => el.id === id)[0].transportType = 'train'
      } else if (state.tripInfo.trips.filter(el => el.id === id)[0].transportType == 'train') {
        state.tripInfo.trips.filter(el => el.id === id)[0].transportType = 'plane'
      }
    },
    resetDepartureCity(state, { id }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].departurePlace = ''
      state.tripInfo.trips.filter(el => el.id === id)[0].departureCityCode = ''
    },
    resetArrivalCity(state, { id }) {
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalPlace = ''
      state.tripInfo.trips.filter(el => el.id === id)[0].arrivalCityCode = ''
    },
    resetSubordinate(state) {
      state.tripInfo.subordinate = ''
      state.tripInfo.subordinateGuid = ''
      state.tripInfo.subordinateEmail = ''
      state.tripInfo.subordinatePosition = ''
    },
    resetNegotiator(state) {
      state.tripInfo.alternateNegotiator = ''
      state.tripInfo.negotiator = ''
    },
    resetCostsCenter(state) {
      state.tripInfo.costsCenter = ''
    },
    loadTripInfo(state, { tripInfo }) {
      state.tripInfo = newTripInfo()
      state.tripInfo = tripInfo
      state.tripInfo.employeeFullname = localStorage.fullname,
      state.tripInfo.employeePosition = localStorage.position,
      state.tripInfo.employeeAddress = localStorage.address,
      state.tripInfo.employeeEmail = localStorage.login,
      state.tripInfo.baggageOptionId = state.tripInfo.trips.every(value => value.isBaggageRequired == true) ? 2 : 1,
      state.tripInfo.trips.forEach(el => {
        el.id = Number(el.tripNumber)
        el.companyId = localStorage.companyId
        el.employeeId = localStorage.employeeId
        el.alternateTransportTypeId = el.alternateTransportTypeId == 0 ? 1 : el.alternateTransportTypeId
        if (el.transportType !== 'plane' && el.transportType !== 'train') {
          el.departureTime = moment(el.departure).format("HH:mm")
          el.arrivalTime = moment(el.arrival).format("HH:mm")
          el.departure = moment(el.departure).format("YYYY-MM-DD")
          el.arrival = moment(el.arrival).format("YYYY-MM-DD")
        }
      });
      console.log(state.tripInfo)
    },
    setHostOrganizationPlace(state, { place }) {
      state.tripInfo.hostOrganization = place
    },
    setBaggage(state, { baggageOptionId }) {
      if (baggageOptionId != null && baggageOptionId != undefined && baggageOptionId != '') {
        state.tripInfo.baggageOptionId = baggageOptionId
      } else {
        state.tripInfo.baggageOptionId = ''
      }
    },
    setBaggageForTrips(state) {
      state.tripInfo.trips.forEach(el => {
        if (state.tripInfo.baggageOptionId == 2) {
          el.isBaggageRequired = true
        } else {
          el.isBaggageRequired = false
        }
      })
    },
    updateSubordinate(state, { subordinate }) {
      state.tripInfo.subordinate = subordinate.value
      state.tripInfo.subordinateGuid = subordinate.guid
      state.tripInfo.subordinateEmail = subordinate.email
      state.tripInfo.subordinatePosition = subordinate.position
    },
    updateNegotiator(state, { negotiator }) {
      state.tripInfo.alternateNegotiator = negotiator.value
      state.tripInfo.negotiator = negotiator.email
    },
    updateCostsCenter(state, { costsCenter }) {
      state.tripInfo.costsCenter = costsCenter.value
    }
  },
  actions: {
    resetTripInfo({ commit }) {
      commit("resetTripInfo")
    },
    addTrip({ commit }) {
      commit("addTrip")
    },
    updateTrip({ commit }, data) {
      commit("updateTrip", data)
    },
    addBackTrip({ commit }) {
      commit("addBackTrip")
    },
    removeBackTrip({ commit }) {
      commit("removeBackTrip")
    },
    removeTrip({ commit }) {
      commit("removeTrip")
    },
    addHotelBlock({ commit }) {
      commit("addHotelBlock")
    },
    removeHotelInfoBlock({ commit }, hotelId) {
      commit("removeHotelInfoBlock", hotelId)
    },
    setHotelPlace({ commit }, { id, place }) {
      commit("setHotelPlace", { id, place })
    },
    setHotel({ commit }, { id, hotel }) {
      commit("setHotel", { id, hotel })
    },
    addTransferBlock({ commit }) {
      commit("addTransferBlock")
    },
    removeTransferBlock({ commit }, transferId) {
      commit("removeTransferBlock", transferId)
    },
    updateDepartureCityCode({ commit }, { id, departureCityCode }) {
      commit("updateDepartureCityCode", { id, departureCityCode })
    },
    updateArrivalCityCode({ commit }, { id, arrivalCityCode }) {
      commit("updateArrivalCityCode", { id, arrivalCityCode })
    },
    setAdditionalTripData({ commit }, { id, additionalData }) {
      commit("setAdditionalTripData", { id, additionalData })
    },
    setDriverData({ commit }, { id, isDriver }) {
      commit("setDriverData", { id, isDriver })
    },
    setTicketSelected({ commit }, { id }) {
      commit("setTicketSelected", { id })
    },
    addThread({ commit }, { id, thread }) {
      commit("addThread", { id, thread })
    },
    addCalculations({ commit }, { tripDuration, routeType, isBusinessTrip, isDriver, originalRequestId }) {
      commit("addCalculations", { tripDuration, routeType, isBusinessTrip, isDriver, originalRequestId })
    },
    addHotelDuration({ commit }, { id, duration }) {
      commit("addHotelDuration", { id, duration })
    },
    setHotelDates({ commit }) {
      commit("setHotelDates")
    },
    removeThread({ commit }, id) {
      commit("removeThread", id)
    },
    resetAlternateTransportType({ commit }, id) {
      commit("resetAlternateTransportType", id)
    },
    changeTripTransportType({ commit }, id) {
      commit("changeTripTransportType", id)
    },
    resetDepartureCity({ commit }, { id }) {
      commit("resetDepartureCity", { id })
    },
    resetArrivalCity({ commit }, { id }) {
      commit("resetArrivalCity", { id })
    },
    resetSubordinate({ commit }) {
      commit("resetSubordinate")
    },
    resetNegotiator({ commit }) {
      commit("resetNegotiator")
    },
    resetCostsCenter({ commit }) {
      commit("resetCostsCenter")
    },
    loadTripInfo({ commit }, { tripInfo }) {
      commit("loadTripInfo", { tripInfo })
    },
    setHostOrganizationPlace({ commit }, { place }) {
      commit("setHostOrganizationPlace", { place })
    },
    setBaggage({ commit}, { baggageOptionId }) {
      commit("setBaggage", { baggageOptionId })
    },
    setBaggageForTrips({ commit }) {
      commit("setBaggageForTrips")
    },
    updateSubordinate({ commit }, { subordinate }) {
      commit("updateSubordinate", { subordinate })
    },
    updateNegotiator({ commit }, { negotiator }) {
      commit("updateNegotiator", { negotiator })
    },
    updateCostsCenter({ commit }, { costsCenter }) {
      commit("updateCostsCenter", { costsCenter })
    }
  },
  // plugins: [vuexLocal.plugin],
}